.not-authed-body {
  height: 100%;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: table;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.auth-form {
  display: table-cell;
  vertical-align: middle;
}